import { createVNode, reactive, h } from 'vue';
import { createModelId, sleepWait } from '@/tools/common';
import { get } from '@/lib/api';
import { message, notification, Progress } from 'ant-design-vue';
import { LoadingOutlined } from '@ant-design/icons-vue';

/** 公共状态参数类型接口 */
interface StateObjType {
  /** 构建进度 */
  buildPercent: number;
  /** 版本id */
  id: string | number;
  /** 构建是否完成 */
  isBuildComplete: boolean;
  /** 构建开始的时间戳 */
  timestamp: number;
  /** 构建包完成的预计时间 */
  estimatedDuration: number;
}

/** 触发事件参数类型接口 */
interface EmitObjType {
  /** 发布环境描述 */
  environmentText: string;
  /** 分发事件触发器 */
  emitCallback?: () => void;
  /** 关闭进度条描述 */
  closeText?: string;
}

/** 获取Jenkins构建进度 */
const getBuildProgress = state => {
  return new Promise((resolve, reject) => {
    get('/system/program/ver/version/build/schedule', {
      versionId: state.id,
    })
      .then(d => {
        state.timestamp = d.timestamp;
        state.estimatedDuration = d.estimatedDuration;
        resolve(d);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/** 获取Jenkins是否构建完成 */
const getIsBuildComplete = (state): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    get(`/system/program/ver/version/build?versionId=${state.id}`)
      .then(d => {
        const _isSuccess = d;
        if (_isSuccess === true) {
          state.isBuildComplete = true;
          resolve(true);
        } else if (_isSuccess === false) {
          reject(new Error('构建未成功'));
        } else {
          resolve(false);
        }
      })
      .catch(reject);
  });
};

/** 显示上传进度
 * @param {object} stateObj 包含了必要参数 如进度条的进度 版本id 构建是否完成 构建包成功开始的时间戳 预计构建时间
 * @param {number} stateObj.buildPercent 进度条的进度
 * @param {string} stateObj.id 版本id
 * @param {boolean} stateObj.isBuildComplete 构建是否完成
 * @param {number} stateObj.timestamp 构建包成功开始的时间戳
 * @param {number} stateObj.estimatedDuration 预计构建时间
 * @param {object} emitObj 进度条结束的时候会触发版本发布之后数据更新事件
 * @param {string} emitObj.environmentText 发布环境话语
 * @param {function} emitObj.emitCallback 分发事件触发回调
 * @param {string} emitObj.closeText 关闭进度条话语
 */
export const showPVNotification = (stateObj: StateObjType, emitObj: EmitObjType) => {
  const state = reactive(stateObj);
  const { environmentText, emitCallback, closeText } = emitObj;
  state.buildPercent = 0;
  const _play = () => {
    if (!state.timestamp) {
      state.buildPercent += 0.01;
      if (state.buildPercent < 90) {
        setTimeout(() => {
          _play();
        }, 100);
      }
    } else {
      const nowTime = new Date();
      const now_timestamp = nowTime.getTime();
      state.buildPercent = ((now_timestamp - state.timestamp) / state.estimatedDuration) * 100;
      if (state.buildPercent < 98) {
        setTimeout(() => {
          _play();
        }, 100);
      }
    }
  };
  const _key = createModelId();
  setTimeout(() => {
    getBuildProgress(state)
      .then(() => {
        /** 判断是否构建成功，最大尝试获取次数200次，间隔3秒钟 */
        sleepWait(() => getIsBuildComplete(state), 3000, 200)
          .then(() => {
            message.info('Jenkins包构建成功');
            state.buildPercent = 100;
            setTimeout(() => {
              notification.close(_key);
              state.buildPercent = 0;
              state.timestamp = 0;
              message.success(environmentText);
              emitCallback?.();
            }, 10000);
          })
          .catch(() => {
            emitCallback?.();
            message.error('Jenkins包构建超时');
          });
      })
      .catch(() => {
        state.buildPercent = 0;
        setTimeout(() => {
          notification.close(_key);
          state.buildPercent = 0;
          state.timestamp = 0;
          message.error('发布失败');
          emitCallback?.();
        }, 10000);
      });
  }, 30000);

  notification.open({
    key: _key,
    message: '打包中',
    duration: 0,
    description: () =>
      h('div', {}, [
        'Jenkins处理中，请等待...',
        createVNode(Progress, {
          percent: state.buildPercent,
          status: 'active',
          showInfo: false,
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
        }),
      ]),
    icon: () => createVNode(LoadingOutlined, { style: 'color: #108ee9' }),
    onClick: () => {},
    onClose: () => {
      message.success(closeText);
      emitCallback?.();
    },
  });
  _play();
};
