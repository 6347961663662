import {
  ProjectDataItem,
  ProjectEditItem,
  ProjectAddItem,
  ProductEditOrAddItem,
  SearchProListItem,
  SearchProjectVerInfoItem,
} from '@/@types/version-manage/Product';
import { PageList, get, post } from '@/lib/api';
import requestHandler from '@/tools/request-handler';
import { IG } from '@antv/g2/lib/dependents';
import { IGetRoutes as IGetReq, IPostRoutes as IPostReq } from '@typings/requests/routes';
import { IGetRoutes as IGetRes } from '@typings/responses/routes';

/** 获取产品列表 */
export const getProductList = (params: IGetReq['/system/program/ver/product/list']) =>
  get('/system/program/ver/product/list', params, { returnPageList: true }) as PageList<IGetRes['/system/program/ver/product/list']>;

/** 获取产品详情 */
export const getProductDetail = requestHandler.get('/system/program/ver/product/id');

/** 根据产品id获取产品相关的所有数据 */
export const getProductAllInfo = requestHandler.get('/system/program/ver/product/allinfo/byId');

/** 删除产品 */
export const delProduct = (productId: number) => get('/system/program/ver/product/remove', { id: productId });

/** 获取产品分支列表 */
export const getBranchList = (params: IGetReq['/system/program/ver/branch/list']) =>
  get('/system/program/ver/branch/list', params, { returnPageList: true }) as PageList<IGetRes['/system/program/ver/branch/list']>;

/** 获取产品分支详细信息 */
export const getBranchDetail = requestHandler.get('/system/program/ver/branch/id');

/** 获取项目详情 */
export const getProjectDetail = requestHandler.get('/system/program/ver/project/id');

/** 新增产品 */
export const addProduct = (params: ProductEditOrAddItem) => post('/system/program/ver/product/add', params);

/** 获取版本列表 */
export const getVersionList = requestHandler.get('/system/program/ver/version/list');

/** 获取版本详情 */
export const getVersionDetail = requestHandler.get('/system/program/ver/version/id');

/** 获取版本基础信息 */
export const getVersionData = requestHandler.get(`/system/program/ver/version/versionData/id`);

/** 编辑版本详情基础信息 */
export const editVersionData = (params: { id: number; versionData: ProjectDataItem[] }) =>
  post('/system/program/ver/version/versionData/edit', params);

/** 获取公共版本详情基础信息 */
export const getCommonVersionData = (params: { id: number }) => get(`/system/program/ver/project/projectData/id`, params);

/** 编辑公共版本详情基础信息 */
export const editCommonVersionData = (params: { id: number; projectData: ProjectDataItem[] }) => post('/system/program/ver/project/edit', params);

/** 删除项目 */
export const delProject = (id: number) => get(`/system/program/ver/project/remove`, { id });

/** 产品编辑 */
export const productEdit = (params: ProductEditOrAddItem) => post('/system/program/ver/product/edit', params);

/** 产品分支编辑 */
export const editProductBranch = (params: IPostReq['/system/program/ver/branch/edit']) => post('/system/program/ver/branch/edit', params);

/** 新增产品分支 */
export const addProductBranch = (params: IPostReq['/system/program/ver/branch/add']) => post('/system/program/ver/branch/add', params);

/** 项目编辑 */
export const editProject = (params: ProjectEditItem) => post('/system/program/ver/project/edit', params);

/** 项目新增 */
export const addProject = (params: ProjectAddItem) => post('/system/program/ver/project/add', params);

/** 查询项目版本以及其他信息列表 */
export const searchProjectVersionInfo = (params: SearchProjectVerInfoItem) => get('/system/program/ver/version/list', params);

/** 查询项目列表 */
export const searchProjectList = (params: SearchProListItem) => get('/system/program/ver/project/list', params);

/** 获取 git 提交列表 */
export const gitCommitList = (projectId: number) => get('/system/program/ver/version/git/commit', { projectId });

/** 提交到质检 */
export const submitCheck = (versionId: number) => post('/system/program/ver/version/quality/inspection', { versionId });

/** 质检审核 */
export const auditCheck = (versionId: number, pass: boolean, description?: string) =>
  post('/system/program/ver/version/quality/inspection/audit', { id: versionId, pass, description });
