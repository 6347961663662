import { PageOpenType } from '@/@types/router';
import { login, logout, getInfo } from '@/api/login';
import router from '@/config/router';
import { reactive } from 'vue';
import mobile from 'is-mobile';
import { removeToken, setToken } from '@/lib/api';
import { message } from 'ant-design-vue';
import { NavigationFailure } from 'vue-router';
import NProgress from 'nprogress';

/** 全局模块状态 */
export const state = reactive({
  num: 1,
  /** 面包屑 */
  links: [] as { title: string; url: string }[],
  /** 用户信息 */
  userInfo: {
    /** Id */
    id: undefined as number | undefined,
    /** 昵称 */
    nickName: '' as string | undefined,
    /** 用户名 */
    name: '',
    /** 头像 */
    avatar: '',
    /** 角色列表 */
    roles: [] as string[],
  },
  /** 是否折叠菜单 */
  collapsed: false,
  /** 当前页面标题 */
  currentPageTitle: '',
  /** 当前页面副标题 */
  currentPageSubTitle: '',
  /** 排除页面（非登录页面） */
  excludePage: ['/redirect', '/login', '/register', '/404', '/401', '/auth-redirect', '/bind', '/patientqr'],
  /** 页面列表 */
  pageList: [] as Record<string, any>[],
  /** 当前是否为移动端 */
  isMobile: false,
  /** 是否显示主菜单 */
  showMainMenu: false,
  /** 当前用户的权限列表 */
  permissions: [] as string[],
});

/** 全局模块逻辑 */
export const service = {
  /** 判断两个页面地址是否一致（主要处理带参数的） */
  pageSame(routePage: string, realPage: string) {
    if (/\/:/g.test(routePage)) {
      const _splitPath = routePage.split('/');
      const _splitRealPath = realPage.split('/');
      if (_splitPath.length !== _splitRealPath.length) return false;
      return _splitPath.every((path, index) => {
        if (path.startsWith(':')) {
          return true;
        } else {
          return _splitPath[index] === _splitRealPath[index];
        }
      });
    } else {
      return routePage === realPage;
    }
  },
  /** 页面跳转函数 */
  async turnPage(page: string, openType: PageOpenType = PageOpenType.routerView) {
    try {
      let _navigationResult: void | NavigationFailure | undefined;
      const _page = state.pageList.find(i => this.pageSame(i.path, page));
      let _openType = openType;
      if (_page?.turnType) _openType = _page.turnType;
      if (page.startsWith('http')) {
        switch (_openType) {
          case PageOpenType.newPage:
            window.open(page);
            break;
          case PageOpenType.currentPage:
            window.location.href = page;
            break;
          case PageOpenType.routerView:
            _navigationResult = await router.push({
              path: '/externa-link',
              query: {
                src: page,
                title: state.pageList.find(i => i.path === page)?.menuName,
              },
            });
            break;
          default:
            break;
        }
      } else {
        switch (_openType) {
          case PageOpenType.newPage:
            window.open(page);
            break;
          case PageOpenType.currentPage:
            _navigationResult = await router.push(page);
            break;
          case PageOpenType.routerView:
            _navigationResult = await router.push(page);
            break;
          default:
            break;
        }
      }
      if (_navigationResult) {
        NProgress.done();
        message.warn('页面跳转失败');
      } else state.showMainMenu = false;
    } catch (error) {
      NProgress.done();
      message.error('页面跳转失败');
    }
  },
  /** 校验是否为移动端 */
  checkIsMobile() {
    if (mobile()) {
      document.body.classList.add('mobile');
      state.isMobile = true;
      return true;
    } else {
      document.body.classList.remove('mobile');
      state.isMobile = false;
      return false;
    }
  },
  /** 清理用户信息 */
  clearUserInfo() {
    state.userInfo.id = undefined;
    localStorage.removeItem('Authorization');
  },
  /** 登录 */
  login(userInfo): Promise<void> {
    const username = userInfo.username.trim();
    const { password } = userInfo;
    const { code } = userInfo;
    const { uuid } = userInfo;
    return new Promise((resolve, reject) => {
      login(username, password, code, uuid)
        .then(data => {
          setToken(data.token);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /** 获取当前用户信息 */
  getUserInfo() {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((res: any) => {
          const { user } = res;
          const avatar = user.avatar === '' ? () => import('@/assets/img/user-head-default.png') : user.avatar;
          if (res.roles && res.roles.length > 0) {
            state.userInfo.roles = res.roles;
            state.permissions = res.permissions;
          } else {
            state.userInfo.roles = ['ROLE_DEFAULT'];
          }
          state.userInfo.name = user.userName;
          state.userInfo.nickName = user.nickName;
          state.userInfo.id = user.userId;
          state.userInfo.avatar = avatar;
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /** 登出 */
  logout(): Promise<void> {
    // return new Promise((resolve) => { resolve(); });
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          state.userInfo.roles = [];
          state.permissions = [];
          router.push('/login');
          removeToken();
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /** 前端 登出 */
  FedLogOut(): Promise<void> {
    return new Promise(resolve => {
      removeToken();
      resolve();
    });
  },
};

/** 全局模块 */
export default {
  /** 全局模块状态 */
  state,
  /** 全局模块逻辑 */
  service,
};
