import { get, post, put, deletes, ApiReturn } from '@/lib/api';
import { upload } from '@/tools/api';
import { parseStrEmpty } from '@/tools/common';
import { IGetRoutes as IGetRes } from '@typings/responses/routes';

type GetAuthRoleListRes = Promise<
  ApiReturn &
    IGetRes['/system/user/authRole'] & {
      total: number;
    }
>;

type GetUserListRes = Promise<
  ApiReturn & {
    rows: IGetRes['/system/user/list'];
    total: number;
  }
>;

type GetUserByIdRes = Promise<
  ApiReturn &
    IGetRes['/system/user'] & {
      total: number;
    }
>;

type GetUserProfileRes = Promise<ApiReturn & IGetRes['/system/user/profile']>;

type PostUserAvatarRes = Promise<
  ApiReturn & {
    imgUrl: '';
  }
>;

/** 查询用户列表 */
export function listUser(query) {
  return get('/system/user/list', query, { returnResponse: true }) as GetUserListRes;
}

/** 查询用户详细 */
export function getUser(userId?: any) {
  return get(`/system/user/${parseStrEmpty(userId)}`, {}, { returnResponse: true }) as GetUserByIdRes;
}

/** 新增用户 */
export function addUser(data) {
  return post('/system/user', data, { returnResponse: true });
}

/** 修改用户 */
export function updateUser(data) {
  return put('/system/user', data, { returnResponse: true });
}

/** 删除用户 */
export function delUser(userId) {
  return deletes(`/system/user/${userId}`, {}, { returnResponse: true });
}

/** 用户密码重置 */
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password,
  };
  return put('/system/user/resetPwd', data, { returnResponse: true });
}

/** 用户状态修改 */
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status,
  };
  return put('/system/user/changeStatus', data, { returnResponse: true });
}

/** 查询用户个人信息 */
export function getUserProfile() {
  return get('/system/user/profile', {}, { returnResponse: true }) as GetUserProfileRes;
}

/** 修改用户个人信息 */
export function updateUserProfile(data) {
  return put('/system/user/profile', data, { returnResponse: true });
}

/** 用户密码重置 */
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword,
  };
  return put('/system/user/profile/updatePwd', data, { returnResponse: true });
}

/** 用户头像上传 */
export function uploadAvatar(data: { avatarfile: Blob }) {
  return upload('/system/user/profile/avatar', data) as PostUserAvatarRes;
}

/** 查询授权角色 */
export function getAuthRole(userId) {
  return get(`/system/user/authRole/${userId}`, {}, { returnResponse: true }) as GetAuthRoleListRes;
}

/** 保存授权角色 */
export function updateAuthRole(data) {
  return put('/system/user/authRole', data, { returnResponse: true });
}

/** 更改密码 */
export function resetPwd(data) {
  return put('/system/user/forgetPwd', data, { returnResponse: true });
}
