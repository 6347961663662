import router, { constantRoutes, dynamicRoutes } from '@/config/router';
import { getRouters } from '@/api/menu';
import auth from '@/config/plugins/auth';
import Layout from '@/views/Layout.vue';
import { defineStore } from 'pinia';
import { state as globalState, service as globalService } from '@/modules/global';
import { cloneLoop } from '@/lib/clone';
import { transformTree } from '@/tools/common';
import { RouteRecordRaw } from 'vue-router';

export const loadView = (view: string) => {
  if (!view) return Layout;
  if (view.includes('modules') && view.includes('/views/')) {
    const sep = '/views/';
    return () => import(`@/${view.split(sep)[0]}/views/${view.split(sep)[1]}`);
  }
  return () => import(`@/views/${view}`);
};

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes: any[]) {
  const res: any[] = [];
  routes.forEach(route => {
    const perms = route.perms || route.permissions;
    if (!perms) {
      //
    } else if (perms) {
      if (auth.hasPermiOr(perms)) {
        res.push(route);
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route);
      }
    }
  });
  return res;
}

/** 处理二层以下的路由 */
function recursiveRoute(routes: RouteRecordRaw[]): RouteRecordRaw[] {
  if (!routes?.length) return [];
  const _list: RouteRecordRaw[] = [];

  /** 递归 */
  const _cb = (newParent: RouteRecordRaw, parent: RouteRecordRaw, chain: RouteRecordRaw[], level: number, rootParent: RouteRecordRaw) => {
    const _item = {
      ...parent,
      children: [],
    };
    chain.push(_item);
    if (parent?.children?.length) {
      for (let i = 0; i < parent.children.length; i++) {
        _cb(_item, parent.children[i], chain, level + 1, rootParent);
      }
    }
    if (level >= 1) {
      // eslint-disable-next-line no-param-reassign
      if (!rootParent.children) rootParent.children = [];
      if (rootParent.children) rootParent.children.push(_item);
    } else {
      // eslint-disable-next-line no-param-reassign
      if (!newParent.children) newParent.children = [];
      if (newParent.children) newParent.children.push(_item);
    }
  };

  routes.forEach(item => {
    const _item = {
      ...item,
      children: [],
    };

    if (item?.children?.length) {
      for (let i = 0; i < item.children.length; i++) {
        _cb(_item, item.children[i], [_item], 0, _item);
      }
    }

    _list.push(_item);
  });

  return _list;
}

export const usePermissionStore = defineStore('permission', {
  state: () => {
    return {
      /** 路由表 */
      routes: [] as any[],
      /** 侧边栏菜单树 */
      sidebarRouters: [] as any[],
    };
  },
  actions: {
    // 生成路由
    generateRoutes() {
      return new Promise(resolve => {
        /* 获取路由数组 */
        getRouters().then((data: Record<string, any>[]) => {
          globalState.pageList = filterDynamicRoutes(data);

          const asyncRoutes = filterDynamicRoutes(dynamicRoutes);

          asyncRoutes.forEach(item => {
            router.addRoute(item);
          });

          /** 校验路由层级从而确认component组件 */
          const _checkRouteLayout = route => {
            if (route.component) {
              return loadView(route.component);
            } else if (route.parentId === 0) {
              return Layout;
            } else {
              const childCount = data.filter(o => o.parentId === route.menuId)?.length;
              if (childCount) {
                return loadView(route.component); // MiddlePage
              } else if (!route.component) {
                return undefined;
              } else {
                return loadView(route.component);
              }
            }
          };

          const remoteBasicListRoutes = data
            .filter(i => {
              if (i.status === '0') {
                if (globalState.permissions.includes('*:*:*')) return true;
                if (i.perms) {
                  if (globalState.permissions.includes(i.perms)) {
                    return true;
                  }
                } else {
                  return false;
                }
              }
              return false;
            })
            .map(i => {
              return {
                id: i.menuId,
                path: i.path.startsWith('http') ? '/' : i.path,
                fullPath: i.path.startsWith('http') ? i.path : i.path,
                parentId: i.parentId,
                component: _checkRouteLayout(i),
                props: route => {
                  return Object.assign(
                    {},
                    ...Object.entries(route.params).map(([key, value]) => ({ [key]: typeof value === 'string' ? Number(value) : value })),
                  );
                },
                meta: {
                  title: i.menuName,
                  turnType: i.turnType,
                  perms: i.perms,
                  menuId: i.menuId,
                  icon: i.icon,
                  link: i.path.startsWith('http') ? i.path : undefined,
                },
              };
            });

          const remoteRoutes = transformTree(cloneLoop(remoteBasicListRoutes));
          const remoteRouteMap = transformTree(remoteBasicListRoutes.filter(i => i.component));
          [...constantRoutes, ...recursiveRoute(remoteRouteMap)].forEach(router.addRoute);
          // 添加404页面
          router.addRoute('layout', { path: '/:pathMatch(.*)*', redirect: '/404', meta: { title: '找不到页面' } });

          const remoteBasicListRoutes2 = data
            .filter(i => {
              if (i.status === '0' && i.visible === '0') {
                if (globalState.permissions.includes('*:*:*')) return true;
                if (i.perms) {
                  if (globalState.permissions.includes(i.perms)) {
                    return true;
                  }
                } else {
                  return false;
                }
              }
              return false;
            })
            .map(i => {
              return {
                id: i.menuId,
                path: i.path.startsWith('http') ? '/' : i.path,
                fullPath: i.path.startsWith('http') ? i.path : i.path,
                parentId: i.parentId,
                component: _checkRouteLayout(i),
                meta: {
                  title: i.menuName,
                  turnType: i.turnType,
                  perms: i.perms,
                  menuId: i.menuId,
                  icon: i.icon,
                  link: i.path.startsWith('http') ? i.path : undefined,
                },
              };
            });

          let remoteRoutes2 = transformTree(cloneLoop(remoteBasicListRoutes2));
          if (!remoteRoutes2?.length) remoteRoutes2 = [];

          this.routes = constantRoutes.concat(remoteRoutes2);
          this.sidebarRouters = [...constantRoutes, ...remoteRoutes2];

          resolve(remoteRoutes);
        });
      });
    },
  },
});
