import { ApiReturn, get, post, put, deletes, PageList } from '@/lib/api';
import { IGetRoutes as IGetRes } from '@typings/responses/routes';

/** 查询字典数据列表 */
export function listData(query) {
  return get('/system/dict/data/list', query, { returnPageList: true }) as PageList<IGetRes['/system/dict/data/list']>;
}

/** 查询字典数据详细 */
export function getData(dictCode) {
  return get(`/system/dict/data/${dictCode}`, {}, { returnResponse: true });
}

/** 根据字典类型查询字典数据信息 */
export function getDicts(dictType: string) {
  return get(`/system/dict/data/type/${dictType}`, {});
}

/** 新增字典数据 */
export function addData(data) {
  return post('/system/dict/data', data, { returnResponse: true });
}

/** 修改字典数据 */
export function updateData(data) {
  return put('/system/dict/data', data, { returnResponse: true });
}

/** 删除字典数据 */
export function delData(dictCode) {
  return deletes(`/system/dict/data/${dictCode}`, {}, { returnResponse: true });
}
