import router from '@/config/router';
import axios from 'axios';
import { ERROR_CODE_MAP } from './response_code';

axios.interceptors.request.use(
  config => config,
  error => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    if (err.response) {
      const res = err.response.data;
      if (ERROR_CODE_MAP[res.status]) {
        router.push('/login');
      }
    }
    return Promise.reject(err);
  },
);
