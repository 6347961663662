import { IPostRoutes as PostReq, IGetRoutes as GetReq, IDeleteRoutes as DeleteReq, IPutRoutes as PutReq } from '@typings/requests/routes';
import { IPostRoutes as PostRes, IGetRoutes as GetRes, IDeleteRoutes as DeleteRes, IPutRoutes as PutRes } from '@typings/responses/routes';
import { type ApiConfig, get as getFetch, post as postFetch, put as putFetch, deletes as deleteFetch } from '@/lib/api';

/** type AllReq = GetReq | PostReq | PutReq | DeleteReq; */
enum RestType {
  get = 'get',
  post = 'post',
  delete = 'delete',
  put = 'put',
}
type AllRes = GetRes | PostRes | PutRes | DeleteRes;

/** 完整返回值类型 */
interface IFullResponse<TResponseType extends AllRes, TUrl extends keyof TResponseType> {
  code: number;
  msg: string | null;
  data: TResponseType[TUrl] | null;
}

/** 返回值类型 */
type IResponse<TReturnResponse extends boolean, TResponseType extends AllRes, TUrls extends keyof TResponseType> = TReturnResponse extends true
  ? IFullResponse<TResponseType, TUrls>
  : IFullResponse<TResponseType, TUrls>['data'];

type RequestServiceType<TRequestType extends RestType> = <
  TGetUrl extends keyof GetReq,
  TPostUrl extends keyof PostReq,
  TDeletetUrl extends keyof DeleteReq,
  TPutUrl extends keyof PutReq,
  TReturnResponse extends boolean = false,
  TReturnPageList extends boolean = false,
>(
  url: TRequestType extends RestType.get
    ? TGetUrl
    : TRequestType extends RestType.post
    ? TPostUrl
    : TRequestType extends RestType.delete
    ? TDeletetUrl
    : TRequestType extends RestType.put
    ? TPutUrl
    : never,
  config?: ApiConfig<TReturnResponse, TReturnPageList>,
) => (
  param?: TRequestType extends RestType.get
    ? GetReq[TGetUrl]
    : TRequestType extends RestType.post
    ? PostReq[TPostUrl]
    : TRequestType extends RestType.delete
    ? DeleteReq[TDeletetUrl]
    : TRequestType extends RestType.put
    ? PutReq[TPutUrl]
    : never,
) => Promise<
  TRequestType extends RestType.get
    ? IResponse<TReturnResponse, GetRes, TGetUrl>
    : TRequestType extends RestType.post
    ? IResponse<TReturnResponse, PostRes, TPostUrl>
    : TRequestType extends RestType.delete
    ? IResponse<TReturnResponse, DeleteRes, TDeletetUrl>
    : TRequestType extends RestType.put
    ? IResponse<TReturnResponse, PutRes, TPutUrl>
    : never
>;

const createHandler =
  <TRequestType extends RestType>(requestFunc: (...params: Parameters<typeof getFetch>) => any): RequestServiceType<TRequestType> =>
  (url, config) =>
  async param =>
    requestFunc(url as string, param ?? {}, config);

/**
 * @description: 用于创建请求函数，可根据url自动推断出参数和返回值类型。例: requestHandler.post(url, {}, { returnResponse: true } ),
 * @param config.returnResponse: 默认false，自动返回response.data。为true则会返回整个response
 */
export const requestHandler = {
  post: createHandler<RestType.post>(postFetch),
  get: createHandler<RestType.get>(getFetch),
  put: createHandler<RestType.put>(putFetch),
  delete: createHandler<RestType.delete>(deleteFetch),
};
export default requestHandler;
