import DataDict from '@/tools/tools-old/dict/index';
import { getDicts } from '@/api/system/dict/data';

function install(app) {
  app.use(DataDict, {
    metas: {
      '*': {
        labelField: 'dictLabel',
        valueField: 'dictValue',
        request(dictMeta) {
          return getDicts(dictMeta.type);
        },
      },
    },
  });
}

export default {
  install,
};
