import G6, { Graph, IGroup, IShape, ModelConfig } from '@antv/g6';

/** 节点背景色 */
const nodeBackgroundColor = (data: Record<string, any>) => {
  if (data.environment === 'production') {
    return {
      master: '#A8071A',
      internal: '#AD8B00',
    }[data.feature];
  } else if (data.environment === 'test') {
    return {
      master: '#a78387',
      internal: '#afa888',
    }[data.feature];
  }
  return undefined;
};

/** 是否为测试节点 */
const isTest = (data: Record<string, any>) => {
  return data.environment === 'test';
};

/** 节点进度颜色 */
const nodeEnvironmentLabel = {
  test: '测试',
  production: '正式',
};

/** 节点进度颜色 */
const nodeProgressColor = {
  测试服测试中: '#D4380D',
  正式服测试中: '#D4B106',
  已发布: '#7CB305',
};

/** 节点进度 */
const nodeProgress = {
  测试服测试中: 0.3,
  正式服测试中: 0.6,
  已发布: 1,
};
/** 节点进度颜色 */
const nodeProgressRadius = {
  测试服测试中: '[0, 0, 0, 4]',
  正式服测试中: '[0, 0, 0, 4]',
  已发布: '[0, 0, 4, 4]',
};

/** 初始化卡片节点 */
export function initCardNode() {
  G6.registerNode(
    'card-node',
    cfg => `
    <group>
      <rect>
        <rect style={{
          width: 160,
          height: 20,
          fill: ${nodeBackgroundColor(cfg)},
          radius: [6, 6, 0, 0],
          cursor: 'move',
          stroke: ${nodeBackgroundColor(cfg)}
        }} draggable="true">
          <circle style={{
              r: 8,
              marginTop: 10,
              marginLeft: 15,
              fill: '#fff'
            }}
          >
            <image style={{
                img: 'https://gw.alipayobjects.com/zos/antfincdn/FLrTNDvlna/antv.png',
                marginTop: -5,
                marginLeft: 9,
                width: 12,
                height: 12
              }}
            />
          </circle>
          <text style={{
            marginTop: -14,
            marginLeft: 28,
            textAlign: 'left',
            fontWeight: 'bold',
            fill: #fff }}>[${nodeEnvironmentLabel[cfg.environment as string]}] {{version}}</text>
        </rect>
        <rect style={{
          width: 160,
          height: 64,
          stroke: ${nodeBackgroundColor(cfg)},
          fill: ${isTest(cfg) ? '#F2F2F2' : '#ffffff'},
          radius: [0, 0, 6, 6],
        }}>
          <text style={{ marginTop: 6, marginLeft: 7, fill: '#333' }}>日期: {{createDate}}</text>
          <text style={{ marginTop: 6, marginLeft: 7, fill: '#333' }}>分支: {{feature}}</text>
          <text style={{ marginTop: 6, marginLeft: 7, fill: '#333' }}>状态: {{status}}</text>
        </rect>
        <rect style={{
          marginLeft: 1,
          marginTop: -3.5,
          height: 3,
          radius: ${nodeProgressRadius[cfg.status as string]},
          width: ${160 * nodeProgress[cfg.status as string] - 2},
          fill: ${nodeProgressColor[cfg.status as string]}
        }}></rect>
      </rect>
    </group>`,
  );
}
