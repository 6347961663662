import Dict from './Dict';
import { mergeOptions } from './DictOptions';

export default (Vue, options) => {
  mergeOptions(options);
  Vue.mixin({
    data() {
      if (!this?.$options?.dicts) {
        return {};
      }
      const dict = new Dict();
      dict.owner = this;
      return {
        dict,
      };
    },
    created() {
      if (!(this.dict instanceof Dict)) {
        return;
      }
      if (options.onCreated) options.onCreated(this.dict);
      this.dict.init(this.$options.dicts).then(() => {
        if (options.onReady) options.onReady(this.dict);
        this.$nextTick(() => {
          this.$emit('dictReady', this.dict);
          if (this.$options.methods && this.$options.methods.onDictReady instanceof Function) {
            this.$options.methods.onDictReady.call(this, this.dict);
          }
        });
      });
    },
  });
};
