import { defineStore } from 'pinia';
import { get } from '@/tools/api';
import { initPropertyEditors } from '@/config/version-manage/data/property-editor';
import { PropertyEditor } from '@/@types/version-manage';
import { Empty } from 'ant-design-vue';

// app版本发布
export const useProduct = defineStore('product', {
  state: () => {
    return {
      /** 空图片 */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      /** 当前页面 */
      currentPage: '' as string,
      /** 当前页面标题 */
      currentPageTitle: '' as string,
      /** 当前页面副标题 */
      currentPageSubTitle: '' as string | undefined,
      /** 属性编辑器库 */
      propertyEditors: initPropertyEditors() as Record<string, PropertyEditor>,
      /** 用户信息 */
      userInfo: {
        /** Id */
        id: undefined as number | undefined,
        /** 角色Code */
        roleKey: '',
        /** 角色名称（备注） */
        roleRemark: '',
        /** 头像 */
        avatar: null,
        /** 生日 */
        birthday: null,
        /** 用户名 */
        name: '',
        /** 昵称 */
        nickName: '',
        /** 手机号码 */
        phone: '',
        /** 性别 */
        sex: 0,
        /** 创建时间 */
        createTime: 0,
        /** 更新时间 */
        updateTime: 0,
      },
      /** 平台列表 */
      platform: {
        'android-app': { text: '安卓APP', icon: 'mobile-outlined', color: '#108ee9' },
        'ios-app': { text: '苹果APP', icon: 'mobile-outlined', color: '#7CB305' },
        'pc-website': { text: 'PC站点', icon: 'mobile-outlined', color: '#2DB7F5' },
        'mobile-website': { text: 'H5站点', icon: 'mobile-outlined', color: '#531DAB' },
        'wechat-mini-app': { text: '微信小程序', icon: 'mobile-outlined', color: '#52C41A' },
        'windows-app': { text: 'Windows应用程序', icon: 'mobile-outlined', color: '#1D39C4' },
      },
      /** 发布版本 */
      environment: {
        production: '正式版本',
        staging: '预发布版本',
        test: '测试版本',
        development: '开发版本',
      },
      /** 版本状态 */
      versionState: {
        building: { text: '编译中', status: 'processing', color: '#1890FF' },
        'build-fail': { text: '编译失败', status: 'error', color: '#FF4D4F' },
        'time-out': { text: '构建超时', status: 'error', color: '#FF4D4F' },
        'publish-error': { text: '编译失败', status: 'error', color: '#FF4D4F' },
        'silent-timeout': { text: '静默期超时', status: 'default', color: '#FF4D4F' },
        'not-enable': { text: '未启用', status: 'default', color: '#D9D9D9' },
        complete: { text: '完成构建', status: 'success', color: '#52C41A' },
      },
    };
  },
  actions: {
    /** 设置UserId */
    setUserId(userId: number) {
      this.userInfo.id = userId;
      localStorage.setItem('userId', `${userId}`);
    },
    getUserId() {
      if (this.userInfo.id) {
        return this.userInfo.id;
      } else if (localStorage.getItem('userId')) {
        const userId = +localStorage.getItem('userId')! as number;
        localStorage.setItem('userId', `${userId}`);
        this.userInfo.id = userId;
        return userId;
      }
      return undefined;
    },
    /** 获取用户信息 */
    getUserInfo(): Promise<Record<string, any>> {
      return new Promise((resolve, reject) => {
        if (this.userInfo.id && this.userInfo.roleKey) {
          resolve(this.userInfo);
        } else {
          const _userId = this.getUserId();
          if (_userId) {
            get('/user/getUserById', { userId: _userId })
              .then(d => {
                this.userInfo = {
                  ...d.data,
                  id: _userId,
                };
                resolve(d.data);
              })
              .catch(err => {
                reject();
              });
          } else {
            reject();
          }
        }
      });
    },
    /** 清理用户信息 */
    clearUserInfo() {
      this.userInfo.id = undefined;
      this.userInfo.roleKey = '';
      localStorage.removeItem('Authorization');
      localStorage.removeItem('userId');
    },
    /** 登出 */
    logout() {
      this.clearUserInfo();
      // router.push('/login');
    },
    openWindow(
      url,
      { onClose } = {
        onClose: () => {},
      },
    ) {
      const _window = window.open(url);
      _window!.onbeforeunload = onClose;
      return _window;
    },
    /** 获取数据源 */
    getDataSource<T>(): Promise<T> {
      return new Promise((resolve, reject) => {});
    },
  },
  getters: {},
});
