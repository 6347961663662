import { storeToRefs } from 'pinia';
import { state as globalState, service as globalService } from '@/modules/global';

function authPermission(permission) {
  const all_permission = '*:*:*';
  if (!permission || !permission.length) {
    return false;
  } else {
    return globalState.permissions.some(v => {
      return all_permission === v || v === permission;
    });
  }
}

function authRole(role) {
  const super_admin = 'admin';
  if (role && role.length > 0) {
    return globalState.userInfo.roles.some(v => {
      return super_admin === v || v === role;
    });
  } else {
    return false;
  }
}

export default {
  /** 验证用户是否具备某权限 */
  hasPermi(permission) {
    return authPermission(permission);
  },
  /** 验证用户是否含有指定权限，只需包含其中一个 */
  hasPermiOr(permissions: string | string[]) {
    if (typeof permissions === 'string') {
      return authPermission(permissions);
    } else {
      return permissions.some(item => {
        return authPermission(item);
      });
    }
  },
  // 验证用户是否含有指定权限，必须全部拥有
  hasPermiAnd(permissions) {
    return permissions.every(item => {
      return authPermission(item);
    });
  },
  // 验证用户是否具备某角色
  hasRole(role) {
    return authRole(role);
  },
  // 验证用户是否含有指定角色，只需包含其中一个
  hasRoleOr(roles) {
    return roles.some(item => {
      return authRole(item);
    });
  },
  // 验证用户是否含有指定角色，必须全部拥有
  hasRoleAnd(roles) {
    return roles.every(item => {
      return authRole(item);
    });
  },
};
