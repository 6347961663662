/** 图表类型 */
export enum ChartType {
  /** 柱状图 */
  bar = 'bar',
  /** 折线图 */
  line = 'line',
  /** 饼图 */
  pie = 'pie',
}

/** 表单项类型 */
export enum FormItemType {
  /** 文本框 */
  text = 'text',
  /** 布尔类型 */
  boolean = 'boolean',
  /** 数字 */
  number = 'number',
  /** 下拉框 */
  select = 'select',
  /** 日期框 */
  date = 'date',
  /** 日期区间框 */
  daterange = 'daterange',
  /** 时间框 */
  datetime = 'datetime',
}

/** 数据源类型 */
export enum DataSourceType {
  /** API接口 */
  api = 'api',
  /** GraphQL查询 */
  graphql = 'graphql',
  /** JSONAPI查询 */
  jsonapi = 'jsonapi',
  /** JSON数据 */
  json = 'json',
}

/** 数据源返回值类型 */
export enum DataSourceResultType {
  /** 无返回值 */
  none = 'none',
  /** 单个值 */
  single = 'single',
  /** 列表 */
  list = 'list',
}

/** 操作类型 */
export enum OperateType {
  /** 创建 */
  create = 'create',
  /** 删除 */
  remove = 'remove',
  /** 更新 */
  update = 'update',
  /** 自定义 */
  custom = 'custom',
}

/** 属性编辑器 */
export enum ComponentPropertyEditor {
  /** 任意值 */
  any = 'any',
  /** 单行文本 */
  singerLine = 'single-line',
  /** 多行文本 */
  multiLine = 'multi-line',
  /** 布尔类型 */
  boolean = 'boolean',
  /** 整数 */
  int = 'int',
  /** 浮点数 */
  float = 'float',
  /** 长度 */
  width = 'width',
  /** 颜色 */
  color = 'color',
  /** Json类型 */
  json = 'json',
  /** 图标类型(vant) */
  icon = 'icon',
  /** 列表型 */
  list = 'list',
  /** 函数类型 */
  function = 'function',
  /** 对象列表类型 */
  modelList = 'model-list',
  /** 表达式 */
  expression = 'expression',
  /** 盒模型编辑器 */
  box = 'box',
  /** 校验规则 */
  rules = 'rules',
  /** 校验规则 */
  richtext = 'richtext',
  /** 日期 */
  date = 'date',
  /** 日期范围 */
  daterange = 'daterange',
  /** 日期时间 */
  datetime = 'datetime',

  /** 组件 */
  component = 'component',
  /** 条件表达式 */
  condition = 'condition',

  /** 变量 */
  variable = 'variable',
}

// TODO: 确认类型
export type PropertyLayout = any;
