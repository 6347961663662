/**
 * @classdesc 字典数据
 * @property {String} label 标签
 * @property {*} value 标签
 * @property {Object} raw 原始数据
 */
export default class DictData {
  /** 标签 */
  label;

  /** 值 */
  value: unknown;

  /** 原始数据 */
  raw: Record<string, any>;

  constructor(label: string, value: unknown, raw: Record<string, any>) {
    this.label = label;
    this.value = value;
    this.raw = raw;
  }
}
