import { createWebHistory, createRouter } from 'vue-router';
import Layout from '../views/Layout.vue';

export const constantRoutes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/Home.vue'),
        name: 'Index',
        meta: { title: '主页', icon: 'dashboard', affix: true },
      },
      {
        path: '/404',
        component: () => import('@/views/error/404.vue'),
        meta: { title: '404' },
      },
      {
        path: '/401',
        component: () => import('@/views/error/401.vue'),
        meta: { title: '401' },
      },
      {
        path: '/externa-link',
        component: () => import('@/views/tool/ExternaLink.vue'),
        meta: { icon: 'user' },
      },
    ],
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/base/Login.vue'),
    hidden: true,
  },
  {
    path: '/register',
    component: () => import('@/views/register.vue'),
    hidden: true,
  },
];

/** 动态路由，基于用户权限动态去加载  */
export const dynamicRoutes = [
  /** 主页 */
  {
    path: '',
    component: () => import('@/views/Layout.vue'),
    hidden: true,
    redirect: '/product/template/test1',
    permissions: ['*:*:*'],
    children: [],
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
});

export default router;
