/** import hasRole from './permission/hasRole'; */
import hasPermi from './permission/hasPermi';
/** import dialogDrag from './dialog/drag'; */
/** import dialogDragWidth from './dialog/dragWidth'; */
/** import dialogDragHeight from './dialog/dragHeight'; */
import clipboard from './module/clipboard';

const install = function (app) {
  /** Vue.directive('hasRole', hasRole); */
  /** 指令判断权限字段是否符合要求 */
  app.directive('hasPermi', hasPermi);
  app.directive('clipboard', clipboard);
  /** Vue.directive('dialogDrag', dialogDrag); */
  /** Vue.directive('dialogDragWidth', dialogDragWidth); */
  /** Vue.directive('dialogDragHeight', dialogDragHeight); */
};

export default install;
