export const icons = [
  { label: '', code: 'F0C8', value: 'success' },
  { label: '', code: 'F0A2', value: 'plus' },
  { label: '', code: 'F042', value: 'cross' },
  { label: '', code: 'F056', value: 'fail' },
  { label: '', code: 'F009', value: 'arrow-up' },
  { label: '', code: 'F007', value: 'arrow-down' },
  { label: '', code: 'F008', value: 'arrow-left' },
  { label: '', code: 'F00A', value: 'arrow' },
  { label: '', code: 'F07C', value: 'location-o' },
  { label: '', code: 'F079', value: 'like-o' },
  { label: '', code: 'F0C4', value: 'star' },
  { label: '', code: 'F0C3', value: 'star-o' },
  { label: '', code: 'F09A', value: 'phone-o' },
  { label: '', code: 'F0B4', value: 'setting-o' },
  { label: '', code: 'F059', value: 'fire-o' },
  { label: '', code: 'F03F', value: 'coupon-o' },
  { label: '', code: 'F026', value: 'cart-o' },
  { label: '', code: 'F0BB', value: 'shopping-cart-o' },
  { label: '', code: 'F024', value: 'cart-circle-o' },
  { label: '', code: 'F05E', value: 'friends-o' },
  { label: '', code: 'F03B', value: 'comment-o' },
  { label: '', code: 'F060', value: 'gem-o' },
  { label: '', code: 'F064', value: 'gift-o' },
  { label: '', code: 'F0A3', value: 'point-gift-o' },
  { label: '', code: 'F0B0', value: 'send-gift-o' },
  { label: '', code: 'F0B2', value: 'service-o' },
  { label: '', code: 'F00F', value: 'bag-o' },
  { label: '', code: 'F0CB', value: 'todo-list-o' },
  { label: '', code: 'F011', value: 'balance-list-o' },
  { label: '', code: 'F034', value: 'close' },
  { label: '', code: 'F032', value: 'clock-o' },
  { label: '', code: 'F0A9', value: 'question-o' },
  { label: '', code: 'F092', value: 'passed' },
  { label: '', code: 'F000', value: 'add-o' },
  { label: '', code: 'F066', value: 'gold-coin-o' },
  { label: '', code: 'F074', value: 'info-o' },
  { label: '', code: 'F09F', value: 'play-circle-o' },
  { label: '', code: 'F093', value: 'pause-circle-o' },
  { label: '', code: 'F0C5', value: 'stop-circle-o' },
  { label: '', code: 'F0DF', value: 'warning-o' },
  { label: '', code: 'F098', value: 'phone-circle-o' },
  { label: '', code: 'F087', value: 'music-o' },
  { label: '', code: 'F0C1', value: 'smile-o' },
  { label: '', code: 'F0C9', value: 'thumb-circle-o' },
  { label: '', code: 'F039', value: 'comment-circle-o' },
  { label: '', code: 'F01D', value: 'browsing-history-o' },
  { label: '', code: 'F0D0', value: 'underway-o' },
  { label: '', code: 'F085', value: 'more-o' },
  { label: '', code: 'F0D5', value: 'video-o' },
  { label: '', code: 'F0B9', value: 'shop-o' },
  { label: '', code: 'F0B7', value: 'shop-collect-o' },
  { label: '', code: 'F02D', value: 'chat-o' },
  { label: '', code: 'F0BF', value: 'smile-comment-o' },
  { label: '', code: 'F0D7', value: 'vip-card-o' },
  { label: '', code: 'F00D', value: 'award-o' },
  { label: '', code: 'F048', value: 'diamond-o' },
  { label: '', code: 'F0D9', value: 'volume-o' },
  { label: '', code: 'F036', value: 'cluster-o' },
  { label: '', code: 'F0DB', value: 'wap-home-o' },
  { label: '', code: 'F09C', value: 'photo-o' },
  { label: '', code: 'F062', value: 'gift-card-o' },
  { label: '', code: 'F052', value: 'expand-o' },
  { label: '', code: 'F083', value: 'medel-o' },
  { label: '', code: 'F068', value: 'good-job-o' },
  { label: '', code: 'F080', value: 'manager-o' },
  { label: '', code: 'F077', value: 'label-o' },
  { label: '', code: 'F01B', value: 'bookmark-o' },
  { label: '', code: 'F018', value: 'bill-o' },
  { label: '', code: 'F06E', value: 'hot-o' },
  { label: '', code: 'F06F', value: 'hot-sale-o' },
  { label: '', code: 'F08B', value: 'new-o' },
  { label: '', code: 'F089', value: 'new-arrival-o' },
  { label: '', code: 'F06A', value: 'goods-collect-o' },
  { label: '', code: 'F054', value: 'eye-o' },
  { label: '', code: 'F013', value: 'balance-o' },
  { label: '', code: 'F0AC', value: 'refund-o' },
  { label: '', code: 'F01A', value: 'birthday-cake-o' },
  { label: '', code: 'F0D4', value: 'user-o' },
  { label: '', code: 'F08F', value: 'orders-o' },
  { label: '', code: 'F0CE', value: 'tv-o' },
  { label: '', code: 'F050', value: 'envelop-o' },
  { label: '', code: 'F05B', value: 'flag-o' },
  { label: '', code: 'F05C', value: 'flower-o' },
  { label: '', code: 'F058', value: 'filter-o' },
  { label: '', code: 'F015', value: 'bar-chart-o' },
  { label: '', code: 'F02C', value: 'chart-trending-o' },
  { label: '', code: 'F01F', value: 'brush-o' },
  { label: '', code: 'F021', value: 'bullhorn-o' },
  { label: '', code: 'F072', value: 'hotel-o' },
  { label: '', code: 'F02A', value: 'cashier-o' },
  { label: '', code: 'F08D', value: 'newspaper-o' },
  { label: '', code: 'F0DE', value: 'warn-o' },
  { label: '', code: 'F08E', value: 'notes-o' },
  { label: '', code: 'F022', value: 'calender-o' },
  { label: '', code: 'F020', value: 'bulb-o' },
  { label: '', code: 'F0D3', value: 'user-circle-o' },
  { label: '', code: 'F047', value: 'desktop-o' },
  { label: '', code: 'F006', value: 'apps-o' },
  { label: '', code: 'F06D', value: 'home-o' },
  { label: '', code: 'F0B6', value: 'share' },
  { label: '', code: 'F0AF', value: 'search' },
  { label: '', code: 'F0A5', value: 'points' },
  { label: '', code: 'F04D', value: 'edit' },
  { label: '', code: 'F044', value: 'delete' },
  { label: '', code: 'F0A8', value: 'qr' },
  { label: '', code: 'F0A7', value: 'qr-invalid' },
  { label: '', code: 'F035', value: 'closed-eye' },
  { label: '', code: 'F04B', value: 'down' },
  { label: '', code: 'F0AE', value: 'scan' },
  { label: '', code: 'F05D', value: 'free-postage' },
  { label: '', code: 'F02B', value: 'certificate' },
  { label: '', code: 'F07F', value: 'logistics' },
  { label: '', code: 'F03E', value: 'contact' },
  { label: '', code: 'F028', value: 'cash-back-record' },
  { label: '', code: 'F003', value: 'after-sale' },
  { label: '', code: 'F051', value: 'exchange' },
  { label: '', code: 'F0D2', value: 'upgrade' },
  { label: '', code: 'F04E', value: 'ellipsis' },
  { label: '', code: 'F030', value: 'circle' },
  { label: '', code: 'F046', value: 'description' },
  { label: '', code: 'F0AB', value: 'records' },
  { label: '', code: 'F0BE', value: 'sign' },
  { label: '', code: 'F03D', value: 'completed' },
  { label: '', code: 'F057', value: 'failure' },
  { label: '', code: 'F04C', value: 'ecard-pay' },
  { label: '', code: 'F096', value: 'peer-pay' },
  { label: '', code: 'F014', value: 'balance-pay' },
  { label: '', code: 'F041', value: 'credit-pay' },
  { label: '', code: 'F043', value: 'debit-pay' },
  { label: '', code: 'F029', value: 'cash-on-deliver' },
  { label: '', code: 'F090', value: 'other-pay' },
  { label: '', code: 'F0CD', value: 'tosend' },
  { label: '', code: 'F097', value: 'pending-payment' },
  { label: '', code: 'F091', value: 'paid' },
  { label: '', code: 'F004', value: 'aim' },
  { label: '', code: 'F04A', value: 'discount' },
  { label: '', code: 'F073', value: 'idcard' },
  { label: '', code: 'F0AD', value: 'replay' },
  { label: '', code: 'F0BD', value: 'shrink' },
];
