/* eslint-disable */
// @ts-nocheck
import { Chart } from '@antv/g2';
import { AttributeOption, AxisOption } from '@antv/g2/lib/interface';
import { ChartType } from '@/@types/version-manage/enum';
import { ComponentChart, ComponentChartConfig } from '@/@types/version-manage/component/component-chart';

/** 初始化基础图表配置 */
export function initChartConfig(chartConfig: ComponentChartConfig = {}): ComponentChartConfig {
  return {
    autoFit: chartConfig.autoFit ?? true,
    height: chartConfig.height ?? 400,
    padding: chartConfig.padding,
  };
}

/** 初始化图表 */
export function initChart<T>(chartId: string, ComponentChartConfig: ComponentChart<T extends ChartType ? ChartType : any>): Chart {
  const chart = new Chart({
    container: chartId,
    ...initChartConfig(ComponentChartConfig.chartBasicConfig),
  });

  const scales = {};
  Object.entries(ComponentChartConfig.scales ?? {}).map(([key, value]) => {
    if (typeof value === 'boolean') {
      scales[key] = value;
    } else {
      scales[key] = value;
    }
  });
  chart.scale(scales);

  Object.entries(ComponentChartConfig.axis ?? {}).map(([key, value]) => {
    chart.axis(key, value as AxisOption);
  });

  chart.tooltip(ComponentChartConfig.tooltip ?? {});

  switch (ComponentChartConfig.chartType) {
    case 'bar':
      initBarChart(chart, ComponentChartConfig as ComponentChart<ChartType.line>);
      break;
    case 'line':
      initLineChart(chart, ComponentChartConfig as ComponentChart<ChartType.line>);
      break;
    case 'pie':
      break;
    default:
      break;
  }

  return chart;
}

/** 初始化柱状图 */
export function initBarChart(chart: Chart, ComponentChartConfig: ComponentChart<ChartType.line>): void {
  const line = chart.line().position(ComponentChartConfig.position);
  if (ComponentChartConfig.color) line.color(ComponentChartConfig.color as AttributeOption);
  line.shape('smooth');
  const area = chart.area().position(ComponentChartConfig.position);
  if (ComponentChartConfig.color) area.color(ComponentChartConfig.color as AttributeOption);
  area.shape('smooth');
}

/** 初始化折线图 */
export function initLineChart(chart: Chart, ComponentChartConfig: ComponentChart<ChartType.line>): void {
  const line = chart.line().position(ComponentChartConfig.position);
  if (ComponentChartConfig.color) line.color(ComponentChartConfig.color as AttributeOption);
  line.shape('smooth');
  const area = chart.area().position(ComponentChartConfig.position);
  if (ComponentChartConfig.color) area.color(ComponentChartConfig.color as AttributeOption);
  area.shape('smooth');
}
